import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import { Link } from "gatsby"

const PublicRelations = ({ pageContext, location }) => {
  const pageTitle = "Public Relations"
  return (
    <div>
      <Layout>
        {/* description for search only, hidden from document and screen readers */}
        <p
          data-typesense-field="description"
          style={{ display: `none` }}
          aria-hidden="true"
        >
          Rocky Brands Sponsorship and Public Relations contacts.
        </p>
        <Seo title={pageTitle} />
        <InnerHeader
          pageTitle={pageTitle}
          pageContext={pageContext}
          location={location}
        />
        <div
          className="inner-container-alt inner-y-padding"
          style={{ display: `flex`, flexDirection: `column`, gap: `2.4rem` }}
        >
          <div style={{ maxWidth: `45rem` }}>
            <h2 className="h3">For all sponsorship opportunities:</h2>
            <p>
              Please{" "}
              <Link to="/public-relations/sponsorship-requests">
                click here
              </Link>
              .
            </p>
          </div>
          <div style={{ maxWidth: `45rem` }}>
            <h2 className="h3">For PR inquiries please contact:</h2>
            <address>
              <p>
                HFS Communications
                <br /> Contact: Emily Snayd <br />
                P: <a href="tel:2033958834">(203) 395-8834</a> <br />
                emily@hfscommunications.com
              </p>
            </address>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default PublicRelations

export const Head = () => (
  <>
    <title data-typesense-field="title">Public Relations</title>
    <meta
      name="description"
      content="Rocky Brands Sponsorship and Public Relations contacts."
    />
  </>
)
